.ButtonContainer{
    background-color: #262626;
    text-align: center;
    border-bottom:solid 3px black;
    padding:20px;
    border-radius: 5px;
    margin-bottom:10px;
    cursor: pointer;
}

.ButtonText{
    margin:auto;
}

.ButtonText{
    color:white;
    font-weight: bolder;
    text-decoration: none;
}
