.Contact-Section {
    text-align: center;
    background-color: #262626 ;
    display: flex;
    flex-wrap: wrap;
    min-height: 50vh;
    align-items: left;
    padding:100px;
    color: #262626;
    font-size: calc(10px + 2vmin);
  }
      
.Contact-Header {
    flex: 75%;
    margin:auto;
    color: #f5f5f5;
  }

  .Contact-Image{
    flex: 25%;
    width: 25%;
    height: 25%;
    margin:auto;
  }
  

  @media (max-width: 1000px) {
    .Contact-Header, .Contact-Image {
      flex: 100%;
    }
    .Contact-Section{
      padding:20px;
    }
  }

 .Contact-Links{
    color:#f5f5f5;
    margin: 20px;
  }

  .Contact-Links img{
    height:12vh;
  }