.About-Section {
    text-align: left;
    background-color: #f5f5f5 ;
    display: flex;
    flex-wrap: wrap;
    min-height: 100vh;
    align-items: left;
    padding:100px;
    color: #262626;
    font-size: calc(10px + 2vmin);
  }
      
.About-Header {
    flex: 75%;
    margin:auto;
  }

  .About-Image{
    flex: 25%;
    width: 25%;
    height: 25%;
    margin:auto;
    border-radius: 100%;
  }
  

  @media (max-width: 1000px) {
    .About-Header, .About-Image {
      flex: 100%;
    }

    .About-Image{
      padding: 100px;
    }
    .About-Section{
      padding:20px;
    }
  }