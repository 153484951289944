.Qualification-Section {
    text-align: right;
    background-color: #262626 ;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    padding:100px;
  }
      
.Qualification-Header, .Qualification-Header a{    
    color: #f5f5f5;
  }

.Qualification-LinksContainer{
    margin-top: 50px;
}

.Qualification-Links{
  color: #f5f5f5;
  margin-left: 100px;
}

.Qualification-Links img{
  height:12vh;
}
  
@media (max-width: 1000px) {
  .Qualification-Section{
    text-align: left;
    padding:20px;
  }

  .Qualification-Links{
    margin-left: 20px;
    margin-top: 50px;
    
  }

}