.ProjectContainer{
    background-color: white;
    color:#262626;
    border-bottom:solid 3px #bfbfbf;
    margin:10px 20px 10px 0px;
    padding:20px;
    flex:20%;
    border-radius: 5px;
    min-width: 300px;
}

.ProjectImg{
    height: 15vh;
    margin: auto;
    max-width: 100%;
}

.ProjectTextContainer{
    margin:auto;
}

a{
    color:#262626;
}

.OpenProjectContainer{
    max-width: 100vw;
    background-color: white;
    color:#262626;
    border-bottom:solid 3px #bfbfbf;
    margin:10px 0px 10px 0px;
    padding:20px;
    flex:20%;
    border-radius: 5px;
    font-size: calc(10px + 0.75vmin);
}

.OpenProjectImg{
    width:100%
}

@media (max-width: 800px) {
    .ProjectContainer{
        flex:100%;
        margin:10px 0px 10px 0px;
    }
  }