.NavBar {
    background-color:#f5f5f5;
    text-align:center;
    overflow:hidden;
    padding:5px 10px;
    position: fixed;
    width: 100vw;
    z-index:100;
}

.NavItem{
    background-color: #f5f5f5;
    padding: 12px;
    text-decoration: none;
    font-size: 18px; 
    line-height: 25px;
    border-radius: 1px;
    margin: 0;
    min-width:100px;
    text-align:center;
    display:inline-block
}

.NavItem:hover{
    background-color: #262626;
}

.NavLink{
    font-weight: bold;
    color:#262626;
}

.NavLink:hover{
    color: #f5f5f5;
}

.HamburgerMenu{
    background-color: #f5f5f5;
    position:fixed;
    padding:20px;
    cursor: pointer;
}

.hamburger_lines
{
  display: block;

  border-bottom: 8px solid #262626;
  margin: 0 10px 10px 10px;
  width: 50px;
  height: 10px;
}

#one
{
  margin-top: 1px;
}

#one, #two, #three
{
    -webkit-animation: mouse-scroll 1s infinite;
    -moz-animation: mouse-scroll 1s infinite;
    animation: mouse-scroll 1s infinite;
  
}

#one
{
  -webkit-animation-delay: .1s;
  -moz-animation-delay: .1s;
  -webkit-animation-direction: alternate;
  
  animation-direction: alternate;
  animation-delay: alternate;
}

#two
{
  -webkit-animation-delay: .2s;
  -moz-animation-delay: .2s;
  -webkit-animation-direction: alternate;
  
  animation-delay: .2s;
  animation-direction: alternate;
  
  margin-top: -6px;
}

#three
{
  -webkit-animation-delay: .3s;
  -moz-animation-delay: .3s;
  -webkit-animation-direction: alternate;
  
  animation-delay: .3s;
  animation-direction: alternate;
  
  
  margin-top: -6px;
}

@keyframes mouse-scroll {

  0%   { opacity: 0; }
  50%  { opacity: .5; }
  100% { opacity: 1; }
}

.HamNavBar, .HamburgerMenu{
  display:none;
  position:fixed;
  z-index:100;
}

#HamNav{
    background-color:#f5f5f5;
    width:100%;
    padding-left: 120px;
  }

#HamNav .NavItem{
    width:100%;
    padding-right: 100px;
    text-align: left;
  }

  @media (max-width: 1000px) {
    .NavBar{
        display:none;
    }

    .HamNavBar, .HamburgerMenu{
        display:inherit;
    }
  }