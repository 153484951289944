.Projects-Section {
    text-align: left;
    background-color: #f5f5f5 ;
    min-height: 100vh;
    align-items: left;
    padding:100px 350px;
    color: #262626;
    font-size: calc(10px + 2vmin);
  }

  .Projects-Container{
    display: flex;
    flex-wrap: wrap;
    font-size: calc(10px + 1vmin);
  }

  @media (max-width: 1000px) {
    .Projects-Header, .Projects-Image {
      flex: 100%;
    }

    .Projects-Section{
      padding:20px;
    }
  }

  .ProjectsButton{
    margin:auto; 
    max-width:200px;
    font-size: calc(10px + 1vmin);;
  }
 
.youtubePlayer{
  max-width: 100%;
}